import React, { useState } from 'react';
import { Container, Typography, TextField, FormControl, FormLabel, RadioGroup, Radio, FormControlLabel, Button, Grid } from '@mui/material';
import Footer from '../Footer/Footer';
const JoinIRCP = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        surname: '',
        dateOfBirth: '',
        title: '',
        nationality: '',
        address: '',
        city: '',
        postcode: '',
        phone: '',
        email: '',
        jobTitle: '',
        workplace: '',
        employer: '',
        motivations: '',
        additionalInfo: '',
        signed: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle form submission logic here
        console.log(formData); // Example: log form data
    };

    return (
        <div style={{ width: '100%', height: 'auto%' }}>
            <a href="/" style={{ textDecoration: 'none' }}>
                <div className="d-flex justify-content-start bordered-div" style={{ maxWidth: '10%', margin: '10px 0 0 10px', borderRadius: '70px', borderColor: 'white' }}>
                    <img className="img-fluid" src="img/logo.png" alt="Central Revolutionary Committee" />
                </div>
            </a>
            <div className="d-flex justify-content-center bordered-div"  style={{ maxWidth: '40%', margin: '0 auto',marginTop:'-10%' }}>
                <img className="img-fluid" src="img/join.png" alt="Central Revolutionary Committee" />
            </div>
            <br></br>
        
        <Container maxWidth="md"  style={{ marginTop: '10px',marginBottom: '50%' }}>

        
            <Typography variant="h4" align="center" style={{ marginBottom: '20px' }}>
                Join IRCP Application
            </Typography>

            <Typography variant="body1" style={{ marginBottom: '20px' }}>
                For the person who is expressing the intention of joining the Internationalist Revolutionary Communist Party:
                <br /><br />
                I, the undersigned, fully aware of the rights and obligations arising from the party's statute, express my free and voluntary will to join the Internationalist Revolutionary Communist Party.
            </Typography>
            <br /><br />

            <form onSubmit={handleSubmit}>
                <Grid container spacing={1.2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            fullWidth
                            name="firstName"
                            label="First Name(s)"
                            value={formData.firstName}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            fullWidth
                            name="surname"
                            label="Surname"
                            value={formData.surname}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            fullWidth
                            name="dateOfBirth"
                            label="Date of Birth"
                            type="date"
                            value={formData.dateOfBirth}
                            onChange={handleChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Title</FormLabel>
                            <RadioGroup
                                row
                                aria-label="title"
                                name="title"
                                value={formData.title}
                                onChange={handleChange}
                            >
                                <FormControlLabel value="Mr" control={<Radio />} label="Mr" />
                                <FormControlLabel value="Mrs" control={<Radio />} label="Mrs" />
                                <FormControlLabel value="Ms" control={<Radio />} label="Ms" />
                                <FormControlLabel value="Dr" control={<Radio />} label="Dr" />
                                <FormControlLabel value="Prof" control={<Radio />} label="Prof" />
                                <FormControlLabel value="Other" control={<Radio />} label="Other" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            name="nationality"
                            label="Nationality"
                            value={formData.nationality}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            name="address"
                            label="Home Address"
                            value={formData.address}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            fullWidth
                            name="city"
                            label="Town / City"
                            value={formData.city}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            fullWidth
                            name="postcode"
                            label="Postcode"
                            value={formData.postcode}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            name="phone"
                            label="Home Phone / Mobile"
                            value={formData.phone}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            name="email"
                            label="Email"
                            value={formData.email}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            name="jobTitle"
                            label="Job Title"
                            value={formData.jobTitle}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            name="workplace"
                            label="Workplace"
                            value={formData.workplace}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            name="employer"
                            label="Employer"
                            value={formData.employer}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            multiline
                            rows={4}
                            name="motivations"
                            label="Your reasons and motivations for joining the IRCP"
                            value={formData.motivations}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            multiline
                            rows={4}
                            name="additionalInfo"
                            label="Additional information (if you would like to add)"
                            value={formData.additionalInfo}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            name="signed"
                            label="Signed"
                            value={formData.signed}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="primary" fullWidth>
                            Send Application
                        </Button>
                    </Grid>
                </Grid>
            </form>
           
        </Container>
    
         <Footer/>
        </div>
    );
};

export default JoinIRCP;
