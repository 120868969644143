import React from 'react';
import './Footer.css'
function Footer() {
    const currentYear = new Date().getFullYear();

    return (
        <div>
            <footer className="footer">
                <center>
                    <a href="https://www.facebook.com/profile.php?id=100075839209435" target="_blank" rel="noopener noreferrer">
                        <img src="/img/icons/fb.png" width="2%" alt="Facebook" />
                    </a>
                    <br />
                </center>
                <center>
                    <p className="footertxt">
                        &copy; {currentYear} IRCP. All rights reserved.
                    </p>
                </center>
            </footer>
        </div>
    );
}

export default Footer;
