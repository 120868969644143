import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import BasicDocuments from './Components/Sections/BasicDocuments';
import Resolutions from './Components/Sections/Resolutions';
import HomePage from './Components/Home/Home';
import Revolutionarythought from './Components/Sections/Revolutionarythought';
import Broadcast from './Components/Sections/Broadcast';
import JoinIRCP from './Components/Sections/Join';
import ContactUs from './Components/Sections/Contactus';

function App() {
  return (
    <Router>
      <Routes>

        <Route path="/" element={<HomePage />} />
        <Route path="" element={<HomePage />} />
        <Route path="/basic-documents" element={<BasicDocuments />} />
        <Route path="/resolutions" element={<Resolutions />} />
        <Route path="/revolutionary-thought" element={<Revolutionarythought />} />
        <Route path="/broadcast" element={<Broadcast />} />
        <Route path="/joinircp" element={<JoinIRCP />} />
        <Route path="/contact" element={<ContactUs />} />

      </Routes>
    </Router>
  );
}

export default App;
