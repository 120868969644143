import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button } from '@mui/material';
import Footer from '../Footer/Footer';
const BasicDocuments = () => {
    const [documents, setDocuments] = useState([
        { id: 1,
         date: '5th of August 2023',
         title: 'INTERNATIONALIST REVOLUTIONARY COMMUNIST PARTY: VOL. 1 - BASIC DOCUMENTS - PARTY STATUTE',
         link: 'https://archive.org/details/internationalist-revolutionary-communist-party-vol.-1-basic-documents-party-statute_202412/page/12/mode/2up' 
        },
        
        // Add more documents as needed
    ]);
    const [filter, setFilter] = useState('');

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const filteredDocuments = documents.filter((doc) =>
        doc.title.toLowerCase().includes(filter.toLowerCase())
    );

    return (
        <div style={{ textAlign: 'center' }}>

            <a href="/" style={{ textDecoration: 'none' }}>
                <div className="d-flex justify-content-start bordered-div" style={{ maxWidth: '10%', margin: '10px 0 0 10px', borderRadius: '70px', borderColor: 'white' }}>
                    <img className="img-fluid" src="img/logo.png" alt="Central Revolutionary Committee" />
                </div>
            </a>
            <div className="d-flex justify-content-center bordered-div"  style={{ maxWidth: '40%', margin: '0 auto',marginTop:'-10%' }}>
                <img className="img-fluid" src="img/basic.png" alt="Central Revolutionary Committee" />
            </div>

            <div style={{ marginBottom: '20px',marginTop:'50px' }}>
            <h2>Basic Documents</h2>
            <TextField
                label="Filter by Title"
                variant="outlined"
                value={filter}
                onChange={handleFilterChange}
                style={{ marginBottom: '10px' ,width: '50%',marginTop:'10px' }}
            />
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow style={{ fontWeight: 'bold',fontFamily:'Jost'}}>
                                <TableCell align="left" style={{ fontWeight: 'bold', color: 'black' }}>Publication Date</TableCell>
                                <TableCell align="left" style={{ fontWeight: 'bold', color: 'black' }}>Title</TableCell>
                                <TableCell align="left" style={{ fontWeight: 'bold', color: 'black' }}>View</TableCell>
                            </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredDocuments.map((document) => (
                            <TableRow key={document.id}>
                                <TableCell>{document.date}</TableCell>
                                <TableCell>{document.title}</TableCell>
                                <TableCell>
                                    <Button variant="contained" color="primary" href={document.link} target="_blank">
                                       Read
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Footer></Footer>
        </div>
    );
};

export default BasicDocuments;
