import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Home.css';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';

import Footer from '../Footer/Footer';
const HomePage = () => {
    
// eslint-disable-next-line
    return (
        <div className='home'>
          
                <div className="d-flex justify-content-center bordered-div">
                    <img className="img-fluid" src="img/mainpg.png" alt="Central Revolutionary Committee" />
                </div>


                <div id="welcome"></div>
                <div id="nav">
                    <style>
                        {`
                            .nav-link, .dropdown-item {
                                color: yellow !important;
                            }
                            .dropdown-item:hover {
                                background-color: #000;
                            }
                        `}
                    </style>
                    <Navbar bg="dark" expand="lg">
                    <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ backgroundColor: '#C0C0C0',bordercolor: '#C0C0C0' }} /> {/* Change to a lighter color */}
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="mr-auto">
                                <Nav.Link href="/">Home</Nav.Link>
                                <NavDropdown bg="dark" title="Party Documents" id="basic-nav-dropdown" menuVariant="dark">
                                    <NavDropdown.Item  href="/basic-documents">Basic Documents</NavDropdown.Item>
                                    <NavDropdown.Item   href="/resolutions">IRCP Resolutions, Guidelines, Circulars</NavDropdown.Item>
                                    <NavDropdown.Item   href="https://reader.crcircp.com/2024/07/04/ircp-ideological-identity-graphic/" target='blank'>IRCP ideological identity graphic</NavDropdown.Item>
                                </NavDropdown>
                                <Nav.Link href="/revolutionary-thought">The Revolutionary Thought</Nav.Link>
                                <Nav.Link href="/broadcast">Broadcast 'The Revolutionary Voice on the world'</Nav.Link>
                                <Nav.Link href="https://archive.org/details/@online_library_of_the_internationalist_revolutionary_communist_party?tab=uploads">Library</Nav.Link>
                                <Nav.Link href="/joinircp">Join IRCP</Nav.Link>
                                <Nav.Link href="/contact">Contact us</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </div>
                <div id="aContent">
                    <div className="d-flex justify-content-center bordered-div">
                        <img className="img-fluid" src="img/lower3.png" alt="Central Revolutionary Committee" />
                    </div>
                    <br /><center><h1>Home</h1></center>
                    <style>
                        {`
                            html {
                                position: relative;
                                min-height: 100%;
                            }
                            .footer {
                                height: 70px;
                                position: absolute;
                                background-color: black;
                                width: 100%;
                                bottom: 0;
                                left: 0;
                            }
                            p.footertxt {
                                color: #fff !important;
                            }
                        `}
                    </style>
                    
                    <Footer></Footer>
                </div>
            
        </div>
    );
};

export default HomePage;
